.enhxo8qehcq4_tr {
    animation: enhxo8qehcq4_tr__tr 2100ms linear infinite normal forwards;
}

@keyframes enhxo8qehcq4_tr__tr {
    0% {
        transform: translate(50px, 50px) rotate(0deg);
    }

    8.095238% {
        transform: translate(50px, 50px) rotate(0deg);
        animation-timing-function: cubic-bezier(0.835, 0.255, 0, 0.975);
    }

    99.047619% {
        transform: translate(50px, 50px) rotate(90.055995deg);
    }

    100% {
        transform: translate(50px, 50px) rotate(90.055995deg);
    }
}

.enhxo8qehcq5_to {
    animation: enhxo8qehcq5_to__to 2100ms linear infinite normal forwards;
}

@keyframes enhxo8qehcq5_to__to {
    0% {
        transform: translate(23px, -23px);
    }

    11.904762% {
        transform: translate(23px, -23px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    28.095238% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    86.190476% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.48, 0.025, 0, 1.02);
    }

    100% {
        transform: translate(21.23924px, -22.268261px);
    }
}

.enhxo8qehcq6_to {
    animation: enhxo8qehcq6_to__to 2100ms linear infinite normal forwards;
}

@keyframes enhxo8qehcq6_to__to {
    0% {
        transform: translate(-22px, -23px);
    }

    9.523810% {
        transform: translate(-22px, -23px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    22.380952% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    80.952381% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.48, 0.025, 0, 1.02);
    }

    98.095238% {
        transform: translate(-23.009786px, -23.017582px);
    }

    100% {
        transform: translate(-23.009786px, -23.017582px);
    }
}

.enhxo8qehcq7_to {
    animation: enhxo8qehcq7_to__to 2100ms linear infinite normal forwards;
}

@keyframes enhxo8qehcq7_to__to {
    0% {
        transform: translate(-22px, 22px);
    }

    4.761905% {
        transform: translate(-22px, 22px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    17.619048% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    76.666667% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.48, 0.025, 0, 1.02);
    }

    95.238095% {
        transform: translate(-23.065528px, 21.945336px);
    }

    100% {
        transform: translate(-23.065528px, 21.945336px);
    }
}

.enhxo8qehcq8_to {
    animation: enhxo8qehcq8_to__to 2100ms linear infinite normal forwards;
}

@keyframes enhxo8qehcq8_to__to {
    0% {
        transform: translate(23px, 22px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    12.857143% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.59, 0.14, 0.045, 1);
    }

    72.857143% {
        transform: translate(0px, 0px);
        animation-timing-function: cubic-bezier(0.48, 0.025, 0, 1.02);
    }

    90.476190% {
        transform: translate(21.915885px, 21.926785px);
    }

    100% {
        transform: translate(21.915885px, 21.926785px);
    }
}
