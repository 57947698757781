// Global variables
:root {
    // COLORS
    // System common
    --color-black: rgba(0, 0, 0, 0.9);
    --color-black-rgb: 0, 0, 0;
    --color-white: #fff;
    --color-system-error: #a8242e;
    --color-system-success: #3c931e;
    --color-system-warning: #de8f08;
    // Configurable
    --color-primary: #a8242e;
    --color-primary-gradient-start: #ad3036;
    --color-primary-gradient-end: #7c2a56;
    --color-secondary: #2f434f;
    --color-highlighted: #e8f6fa;
    // Configurable neutrals
    --color-darkest-blue: #223944;
    --color-dark-grey-adjusted: #587179;
    --color-medium-grey: #c1ced2;
    --color-grey: #d5e0e3;
    --color-light-grey: #e6ebf0;
    --color-bright-grey: #f4f6f8;
    --color-pdf-background: #dfe2e3;
    --color-custom-background: #fff;

    // LAYOUT
    --content-width: 860px;
    --sidebar-mobile-width: 70px;
    --sidebar-desktop-width: 280px;

    // ADMIN PORTAL HEADER
    --admin-header-height-tablet: 70px;
    --admin-header-height-desktop: 110px;

    // global transition
    --global-transition: all 0.3s ease-in;
}
