@import '~bootstrap/scss/mixins/_text-truncate';

// Animations
@mixin fadeIn {
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

@mixin moving {
    @keyframes moveToLeft {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
}

// Media queries
@mixin touchOnly() {
    @media (pointer: coarse) {
        @content;
    }
}

// RTL
@mixin rtl($property, $ltr-value, $rtl-value) {
    html[dir='ltr'] & {
        #{$property}: $ltr-value;
    }
    html[dir='rtl'] & {
        #{$property}: $rtl-value;
    }
}

// Spacings
@mixin padding-start($value) {
    html[dir='ltr'] & {
        padding-left: $value;
    }
    html[dir='rtl'] & {
        padding-right: $value;
    }
}

@mixin padding-end($value) {
    html[dir='ltr'] & {
        padding-right: $value;
    }
    html[dir='rtl'] & {
        padding-left: $value;
    }
}

@mixin margin-start($value) {
    html[dir='ltr'] & {
        margin-left: $value;
    }
    html[dir='rtl'] & {
        margin-right: $value;
    }
}

@mixin margin-end($value) {
    html[dir='ltr'] & {
        margin-right: $value;
    }
    html[dir='rtl'] & {
        margin-left: $value;
    }
}

// Borders
@mixin border-start($value) {
    html[dir='ltr'] & {
        border-left: $value;
    }
    html[dir='rtl'] & {
        border-right: $value;
    }
}

@mixin border-end($value) {
    html[dir='ltr'] & {
        border-right: $value;
    }
    html[dir='rtl'] & {
        border-left: $value;
    }
}

// Positioning
@mixin start($value) {
    html[dir='ltr'] & {
        left: $value;
    }
    html[dir='rtl'] & {
        right: $value;
    }
}

@mixin end($value) {
    html[dir='ltr'] & {
        right: $value;
    }
    html[dir='rtl'] & {
        left: $value;
    }
}
