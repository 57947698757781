@import './/mediaQueries.scss';
@import './mixins';

html,
body,
#root {
    height: 100%;
    overflow: hidden;
    *:focus {
        @include touchOnly() {
            outline: none;
            box-shadow: none;
        }
    }
}

.noFocus *:focus {
    outline: none !important;
    box-shadow: none !important;
}

input:required {
    box-shadow: none; // Firefox fix
}

textarea {
    resize: none;
}

::placeholder {
    opacity: 1;
    color: var(--color-dark-grey-adjusted);
}

.error-border {
    border-color: var(--color-primary) !important;
}

.bg-none {
    background: none;
}

.bg-white {
    background-color: var(--color-white);
}

.bg-light-grey {
    background-color: var(--color-bright-grey);
    padding: 2em;
    border: 1px solid var(--color-light-grey);
}

.border-radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.border-radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.hr-grey {
    border: none;
    height: 1px;
    margin: 0;
    background-color: var(--color-medium-grey);
}

.logo-icon {
    height: 16px;
    width: 16px;

    &.md {
        height: 32px;
        width: auto;
    }
}

.content-max-width {
    max-width: var(--content-width);
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.flex-content-wrap {
    min-width: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.rtl-mirror {
    [dir='rtl'] & {
        transform: scaleX(-1);
    }
}
