@import './mixins';

.table-fixed {
    table-layout: fixed;
    background-color: var(--color-white);

    .fake-row {
        display: table-row;
    }

    thead {
        border-bottom: 1px solid var(--color-medium-grey);
    }

    tbody {
        tr:nth-of-type(odd),
        tr:nth-of-type(odd) + .fake-row {
            background-color: var(--color-bright-grey);
        }

        tr:focus {
            outline: none;
            box-shadow: inset 0 0 0 1px var(--color-white), inset 0 0 0 3px var(--color-grey);
            border-radius: 8px;

            &:nth-of-type(odd) {
                box-shadow: inset 0 0 0 1px var(--color-bright-grey), inset 0 0 0 3px var(--color-grey);
            }
        }
    }

    tr,
    td,
    th {
        padding: 0.5rem 1rem;
    }

    td {
        &:first-child {
            @include padding-end(0);
        }
    }
}
