@import '../../../styles/mixins';
@import '../../../styles/mediaQueries';

.button {
    width: auto;
    min-width: 120px;
    padding: 14px 24px;
    border: 0;
    border-radius: 24px;
    transition: all 0.2s linear;
    text-align: center;
    cursor: pointer;
    @include text-truncate();

    &.primary {
        color: var(--color-white);
        background-color: var(--color-primary);
        background-image: linear-gradient(
            135deg,
            var(--color-primary-gradient-start) 0%,
            var(--color-primary-gradient-end) 100%
        );

        &:hover,
        &:focus {
            outline: none;
            background-image: linear-gradient(
                    0,
                    rgba(var(--color-black-rgb), 0.1) 0%,
                    rgba(var(--color-black-rgb), 0.1) 100%
                ),
                linear-gradient(135deg, var(--color-primary-gradient-start) 0%, var(--color-primary-gradient-end) 100%);
        }
    }

    &.secondary {
        color: var(--color-dark-grey-adjusted);
        background: var(--color-white);

        &:hover,
        &:focus {
            outline: none;
            background: var(--color-bright-grey);
        }
    }

    &.disabled {
        color: var(--color-dark-grey-adjusted);
        background: var(--color-bright-grey);

        &:hover,
        &:focus {
            outline: none;
            background: var(--chakra-colors-gray-100);
        }
    }

    &.plain {
        min-width: 190px;
        padding: 10px 20px;
        border-radius: 20px;
        color: var(--color-darkest-blue);
        background: var(--color-bright-grey);

        &:hover,
        &:focus {
            outline: none;
            background: var(--color-light-grey);
        }
    }

    &.small {
        height: 48px;
        min-width: 120px;
        padding: 8px 16px;
        border-radius: 16px;
    }

    &.link {
        padding: 0;
        overflow: visible;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &[disabled] {
        color: var(--color-darkest-blue);
        background: var(--color-medium-grey) !important;
        cursor: default;
        user-select: none;
        pointer-events: none;
    }
}
