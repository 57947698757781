@import '../../styles/mixins';

.loader {
    z-index: 10;

    &.sm {
        height: 10px;
        width: 10px;
    }

    &.md {
        height: 20px;
        width: 20px;
    }

    &.lg {
        height: 64px;
        width: 64px;
    }

    &.center {
        position: absolute;
        top: 50%;
        @include start(50%);
    }
}
