// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                // End spacings
                html[dir='ltr'] .#{$abbrev}e#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }

                html[dir='rtl'] .#{$abbrev}e#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }

                // Start spacings
                html[dir='ltr'] .#{$abbrev}s#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }

                html[dir='rtl'] .#{$abbrev}s#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
            }
        }

        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size, $length in $spacers {
            @if $size != 0 {
                html[dir='ltr'] .me#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }

                html[dir='rtl'] .me#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }

                html[dir='ltr'] .ms#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }

                html[dir='rtl'] .ms#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }
            }
        }

        // Some special margin utils
        html[dir='ltr'] .ms#{$infix}-auto,
        html[dir='rtl'] .me#{$infix}-auto {
            margin-left: auto !important;
        }
        html[dir='rtl'] .ms#{$infix}-auto,
        html[dir='ltr'] .me#{$infix}-auto {
            margin-right: auto !important;
        }
    }
}
