@import './mixins';

html,
body {
    font-family: 'Helvetica Neue', Helvetica, 'San Francisco', Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: var(--color-darkest-blue);
}

body {
    text-align: initial;
}

h1,
.title-large {
    font-size: 28px;
    font-weight: normal;
    line-height: 34px;

    @include media-breakpoint-up(lg) {
        font-size: 48px;
        font-weight: 300;
        line-height: 58px;
        @include rtl(letter-spacing, -0.01em, 0);
    }
}

h2,
.title {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;

    @include media-breakpoint-up(lg) {
        font-size: 28px;
        font-weight: normal;
        line-height: 34px;
        @include rtl(letter-spacing, -0.01em, 0);
    }
}

h3,
.menu-item-large {
    font-size: 20px;
    font-weight: normal;
    line-height: 56px;
    @include rtl(letter-spacing, -0.02em, 0);
}

h4,
.menu-item {
    font-size: 18px;
    font-weight: normal;
    line-height: 48px;

    @include media-breakpoint-up(lg) {
        line-height: 42px;
    }
}

h5,
.text-large {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 22px;
    }
}

h6,
p,
.text-medium {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
}

small,
.text-small {
    font-size: 14px;
    line-height: 18px;
}

.text-xtrasmall {
    font-size: 10px;
    line-height: 14px;
}

.text-tiny {
    font-size: 8px;
    line-height: 12px;
}

a {
    color: var(--color-primary);
    cursor: pointer;

    &.styleless,
    &.styleless:hover {
        color: inherit;
        text-decoration: none;
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--color-light-grey);
        border-radius: 4px;
        outline: none;
    }
}

// Helpers
.bold {
    font-weight: bold;
}

.bolder {
    font-weight: 500;
}

.word-break {
    word-break: break-word;
}

.pre-line {
    white-space: pre-line;
}

.text-grey {
    color: var(--color-dark-grey-adjusted);
}

.color-black {
    color: var(--color-black) !important;
}

.color-error {
    color: var(--color-system-error);
}

.color-primary {
    color: var(--color-primary) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-darkest-blue {
    color: var(--color-darkest-blue) !important;
}

.fs-0 {
    font-size: 0;
}

.text-start {
    @include rtl(text-align, left, right);
}

.text-end {
    @include rtl(text-align, right, left);
}
